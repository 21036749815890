import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ArticleRows from "../layouts/ArticleRows";

const OverviewPage = () => {
  const data = useStaticQuery(graphql`
    query OverviewPageQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/content/overview/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            body
            id
            frontmatter {
              title
              description
              anchor
              logos
              image {
                childImageSharp {
                  gatsbyImageData(
                    height: 300
                    formats: [AUTO, WEBP]
                    placeholder: BLURRED
                    backgroundColor: "transparent"
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <ArticleRows
      title="Overview"
      description="Music Glue build bespoke ecommerce for the music industry<br/>Our partners sell merch, music and tickets in a single transaction directly to customers around the world and own all the data"
      data={data.allMdx.edges}
    />
  );
};

export default OverviewPage;
