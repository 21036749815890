import * as React from "react";
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import { ArticleLayout, ArticleProps } from "../layouts/ArticleRows";
import Link from "./Link";
import gsap from "gsap";
import { useEffect } from "react";
import { widdowJoiner } from "../utils/stringUtils";

interface BannerProps {
  title?: string;
  intro?: string;
  actionLink?: string;
  actionText?: string;
  headerImage?: IGatsbyImageData;
  articleLinks?: ArticleProps[];
  children?: React.ReactNode;
}

const Banner = ({
  title,
  intro,
  actionLink,
  children,
  actionText,
  headerImage,
  articleLinks,
}: BannerProps) => {
  const introParagraphs = intro?.split("<br/>") ?? [];

  useEffect(() => {
    const layer = gsap.utils.toArray(".Paralax") as HTMLElement[];

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#Hero",
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });

    layer.forEach((layer, i, a) => {
      const depth = -i / (a.length + 1) - 0.25;
      const movement = -(layer.offsetHeight * depth);
      tl.to(layer, { y: movement, ease: "none" }, 0);
    });
  }, []);

  return (
    <section
      id="Hero"
      className={`bg-mg-purple text-center text-white relative overflow-hidden ${
        children ? "min-h-screen flex flex-col" : ""
      }`}
    >
      {headerImage ? (
        <GatsbyImage
          role="presentation"
          className="Paralax inset-0 z-0 opacity-20"
          style={{ position: "absolute" }}
          image={headerImage}
          alt=""
        />
      ) : (
        <StaticImage
          role="presentation"
          className="Paralax inset-0 z-0 opacity-30"
          style={{ position: "absolute" }}
          src="../assets/img/backgrounds/artist-grid.jpeg"
          alt=""
          layout="fullWidth"
        />
      )}
      <div className="container relative z-1 px-4 max-w-5xl pt-32 pb-10 lg:pt-36 lg:pb-14 m-auto">
        {title ? <h1 className="text-4xl lg:text-5xl mb-6 capitalize">{title}</h1> : ""}
        {intro
          ? introParagraphs.map((p, i) => (
              <p className="container text-lg mx-auto" key={i}>
                {widdowJoiner(p)}
              </p>
            ))
          : ""}
        {actionLink ? (
          <Link
            className="btn max-w-lg text-xl my-6 block mx-auto p-3 hover:bg-white hover:text-mg-purple"
            to={actionLink}
          >
            {actionText}
          </Link>
        ) : (
          ""
        )}
        {articleLinks ? (
          <div className="overflow-auto -mx-4 md:mx-0 mix-blend-screen scrollbar-hide whitespace-nowrap md:whitespace-normal after after:absolute after:right-0 after:bg-gradient-to-r after:from-transparent after:to-black after:h-11 after:w-11 after:pointer-events-none before before:absolute before:left-0 before:bg-gradient-to-r before:from-black before:to-transparent before:h-11 before:w-11 before:pointer-events-none px-6">
            {articleLinks?.map((a: ArticleProps) => (
              <a
                className="px-4 py-2 text-sm m-1 border btn-ghost"
                key={a.id}
                href={`#${a.anchor}`}
              >
                {a.title}
              </a>
            ))}
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </section>
  );
};

export default Banner;
